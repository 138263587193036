import { GatsbyButton } from '@components/atm.button/button.component';
import { sendDataLayer } from '@web/utils/analytics';
import { useCopyOrShareInfo } from '@web/utils/copy-or-share-info.hook';
import { hasDocument } from '@web/utils/platform';
import React, { useEffect, useRef } from 'react';
import { IInstructionsProps } from './instructions.utils';
import TurndownService from 'turndown';

const SendInstructionsButton: React.FunctionComponent<IInstructionsProps> = (props) => {
  const __html = props.content;
  const turndownRef = useRef<TurndownService>();
  useEffect(() => {
    const turndownService = new TurndownService();
    turndownService.addRule('remove-link', {
      filter: 'a',
      replacement(content) {
        return content;
      },
    });
    turndownRef.current = turndownService;
  }, []);

  const successMessage = 'Instruções copiadas!';
  const errorMessage = 'Não foi possível copiar as instruções. 😢';
  const { shareAvailable, copyOrShare } = useCopyOrShareInfo({ successMessage, errorMessage });
  const handleClick = () => {
    const title = props.title;
    const text = `${turndownRef.current.turndown(__html)}\n`;
    // https://stackoverflow.com/questions/5980682/obtaining-canonical-url-using-javascript
    const url = hasDocument() && document.querySelector("link[rel='canonical']").getAttribute('href');
    copyOrShare({ text, title, url });
    sendDataLayer({
      event: 'custom_click',
      id: `ga-SendInstructionsButton-copy-instructions`,
    });
  };

  const buttonId = shareAvailable ? 'ga-instructions-share' : 'ga-instructions-copy';
  return (
    <GatsbyButton id={buttonId} kind="primary" onClick={handleClick} expanded>
      {shareAvailable ? 'Enviar instruções' : 'Copiar instruções'}
    </GatsbyButton>
  );
};

export default SendInstructionsButton;
